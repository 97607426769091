import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import PageBlock from "../../components/Layout/PageBlock";
import PageLayout from "../../components/Layout/PageLayout";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

import { ArrowRight } from "react-feather";
import { PageBlockTitle, staticImgContainer, navBarHeight, CTALink, PageBlockNextPageDivider, primaryColour } from "../../styles/component-stylings";

const PersonTitleAnchor = styled(PageBlockTitle)`
    // to account for navbar
    padding-top: ${navBarHeight};
    margin-top: -${navBarHeight};
`;

const QuestionTitle = styled.h3`
    margin: 1rem 0;
    font-size: 1rem;
    font-weight: bold;
`;

const PAGE_TITLE = "Our people";

const OurPeoplePage = ({ pageContext }) => {
    return (
        <PageLayout pageTitle={PAGE_TITLE} pageContext={pageContext}>
            <Helmet>
                <meta name='description' content='Testimonials from some employees at Digital Surveillance Collection from the Australian Federal Police' />
            </Helmet>
            <PageBlock pageContext={pageContext} backgroundColour='white' isFirstBlock>
                <Breadcrumb pageContext={pageContext} />
                <h1>{PAGE_TITLE}</h1>
                <p>
                    The strength of DSC is our people - without them, we cannot continue to disrupt complex criminal networks and protect Australians and
                    Australia's interests. We are always on the look out for innovative and creative people who think outside the box and provide unique
                    solutions.
                </p>
                <hr />

                {/*  */}
                <PersonTitleAnchor id='A'>
                    Ailie, Technician.
                    <br />
                    Entered as a Direct Hire.
                </PersonTitleAnchor>
                <StaticImage
                    style={staticImgContainer}
                    imgStyle={{ objectPosition: "center 85%" }}
                    src='../../images/computer-screens.jpg'
                    alt='Computer Screens'
                    placeholder='blurred'
                    loading='lazy'
                />
                <QuestionTitle>Who are you?</QuestionTitle>
                <p>
                    I am a Technician, which involves maintaining our current interception platform and providing first level support to internal investigators
                    and external agencies.
                </p>
                <QuestionTitle>Why did you apply to the AFP?</QuestionTitle>
                <p>
                    Originally joining the AFP as an operational member in Canberra, I was always interested in general policing, then I was introduced to the
                    whole new world of the AFP. I read about DSC and the diversity of the work they did, the devices they created, the software they develop and
                    what they were able to do with all this. I was blown away and knew I wanted to be part of the area.
                </p>
                <QuestionTitle>What do you enjoy about the work?</QuestionTitle>
                <p>I like being able to support our frontline and seeing how all areas of the DSC and the AFP work together to achieve amazing results.</p>
                <QuestionTitle>How would you describe the DSC work culture?</QuestionTitle>
                <p>
                    Having worked in other areas and teams in the AFP, I can say without a doubt that DSC has the best working culture. Everyone is
                    approachable, helpful and easy-going. We try to do yearly get-togethers where interstate members are flown into Canberra which reinstates
                    that we are just one big family.
                </p>
                <QuestionTitle>What are some highlights?</QuestionTitle>
                <p>
                    Getting to travel to Alice Springs for an operation with a tactical team was one of my best experiences. But it's also the little things,
                    knowing that I helped an investigator with a task that allowed them to locate a drug trafficker and get drugs off of the streets.
                </p>
                <QuestionTitle>What are your career plans?</QuestionTitle>
                <p>
                    I plan on staying within DSC for some time, moving into more project work to support new digital surveillance initiatives and support the
                    recruitment of more female techs into the area.
                </p>

                <QuestionTitle>What training and development have you received?</QuestionTitle>
                <p>
                    Coming from a non-technical background, I have been supported in further training throughout the year. This is great as my team know me and
                    are able to identify what training would suit me and benefit the area.
                </p>
                <QuestionTitle>Anything else you want to share?</QuestionTitle>
                <p>The only constant in the field of technology is change.</p>

                {/*  */}
                <hr />
                <PersonTitleAnchor id='B'>
                    Brandon, Software Engineer
                    <br />
                    Entered as a Graduate.
                </PersonTitleAnchor>
                <StaticImage
                    style={staticImgContainer}
                    imgStyle={{ objectPosition: "center 80%" }}
                    src='../../images/multiple-monitors.jpg'
                    alt='Multiple Monitors'
                    placeholder='blurred'
                    loading='lazy'
                />
                <QuestionTitle>Who are you?</QuestionTitle>
                <p>
                    I am a Software Engineer in Operational Capability Development (OCD). We solve difficult technical problems to enhance the AFP's technical
                    capability in order to assist operations.
                </p>
                <QuestionTitle>Why did you apply to the AFP?</QuestionTitle>
                <p>
                    The diversity of work and real life operational outcomes were the driving factors behind my application. There aren't many software
                    development jobs that allow you to see outcomes benefit the public in such varying ways - whether that be disrupting a drug shipment or
                    larger scale projects such as Operation Ironside.
                </p>
                <QuestionTitle>What are you working on now?</QuestionTitle>
                <p>
                    I'm a jack of all trades - software development, reverse engineering, systems administration, process automation, and much, much more. I am
                    also deployed to assist with operations when required.
                </p>
                <QuestionTitle>How would you describe the DSC work culture?</QuestionTitle>
                <p>
                    While the work is super interesting, the people are who make DSC a great place to work. Everyone is welcoming and easy-going - no question
                    is considered a dumb question, making DSC a great place to learn.
                </p>
                <QuestionTitle>What are some highlights?</QuestionTitle>
                <p>
                    Qualifying as a Special Member was something that I never would have expected to do when I left university. It was a challenging and
                    rewarding experience, further highlighting the diversity of opportunities available at the AFP.
                </p>
                <QuestionTitle>What has your career trajectory been like?</QuestionTitle>
                <p>
                    Joining DSC and more broadly, the AFP, is like a choose-your-own-adventure game. There are so many different areas and roles that you can
                    try out, with temporary transfers and acting opportunities all over the place.
                </p>
                <p>
                    I started as a Graduate in 2017, rotating through various teams in DSC and a stint in Cybercrime. Upon completion, I slotted into a software
                    development role in OCD where I eventually "dual-hatted" a role in our Digital Surveillance Operations team. This gave me the opportunity to
                    take myself way out of my comfort zone, completing covert and Special Member training, meaning I am use-of-force and firearm qualified to
                    support of DSC operations. Since then, I have continued to progress through the band levels in OCD - recently becoming a Technical
                    Specialist. I have also spent various periods acting as Team Leader.
                </p>
                <QuestionTitle>What benefits do you enjoy?</QuestionTitle>
                <ul>
                    <li>
                        Constant Learning - there's not a day on the job that I don't learn something new. We're always pushing limits and boundaries on what
                        can be done. It keeps the job exciting.
                    </li>
                    <li>Flexibility - the workplace is quite flexible, allowing work from home or interstate placement along with flex time.</li>
                    <li>Conditions - on top of the flexibility, we get generous leave entitlements including 6 weeks annual leave and Christmas shutdown.</li>
                </ul>
                <QuestionTitle>What training and development have you received?</QuestionTitle>
                <p>
                    I have been afforded the opportunity to participate in various industry-leading training courses. DSC is supporting of training and
                    development opportunities.
                </p>
                <QuestionTitle>Anything else you want to share?</QuestionTitle>
                <p>Say yes to as many opportunities as you can, even if they do push you outside your comfort zone. It leads you to some pretty cool places.</p>

                {/*  */}
                <hr />
                <PersonTitleAnchor id='I'>
                    Isobel, Software Developer.
                    <br />
                    Entered as a Graduate.
                </PersonTitleAnchor>
                <StaticImage style={staticImgContainer} src='../../images/pc.jpg' alt='Programming on multiple monitors' placeholder='blurred' loading='lazy' />
                <QuestionTitle>Who are you?</QuestionTitle>
                <p>
                    I am a software developer in the Covert Systems Team which is responsible for infrastructure and development of applications for DSC and
                    other areas on request.
                </p>
                <QuestionTitle>Why did you apply to the AFP?</QuestionTitle>
                <p>The AFP provides the opportunity to assist with and support work that has direct and visible positive outcomes.</p>
                <QuestionTitle>What are you working on now?</QuestionTitle>
                <p>Largely python development, web development and virtualisation/containerisation.</p>
                <QuestionTitle>What do you like about the work?</QuestionTitle>
                <p>
                    Variety in the work that we do. This includes the opportunity to work with a range of technologies and objectives. It also applies to the
                    pace of work which can be busy sprints to deliver operationally-driven requests in a short timeframe, as well as continuous improvement and
                    development of long term projects.
                </p>
                <QuestionTitle>How would you describe the DSC work culture?</QuestionTitle>
                <p>
                    Teams are easy-going, respect the need for work-life balance, flexible hours and taking breaks to refocus. In order to meet tight
                    operational deadlines when necessary, there is a very supportive, collaborative environment within and between teams to ensure work is
                    completed on time to ensure everyone has the assistance required to meet objectives.
                </p>
                <QuestionTitle>What training and development have you received?</QuestionTitle>
                <p>
                    There are plenty of opportunities for formal training and certifications. In addition to this, the areas are supportive of self-learning
                    time and over the years, teams and individual members have hosted shared learning sessions for the area regarding work the teams are doing,
                    and on technologies that may be valuable across various areas.
                </p>

                {/*  */}
                <hr />
                <PersonTitleAnchor id='D'>
                    David, Mechatronics Engineer.
                    <br />
                    Change of career, entered as a Graduate.
                </PersonTitleAnchor>
                <StaticImage style={staticImgContainer} src='../../images/soldering-chip.jpg' alt='Soldering Chip' placeholder='blurred' loading='lazy' />
                <QuestionTitle>Who are you?</QuestionTitle>
                <p>I am a Mechatronics (or Robotics) Engineer in the AFP, working on electronic and mechanical design of products for operational use.</p>
                <QuestionTitle>Why did you apply to the AFP?</QuestionTitle>
                <p>
                    I was interested in the type of work that the AFP was undertaking. I was getting frustrated making private companies richer, and wanted to
                    add value to society. I now derive a fair amount of intrinsic value from the fact that I am now helping catch bad guys that would otherwise
                    cause harm to real people in the real world.
                </p>
                <QuestionTitle>What are you working on now?</QuestionTitle>
                <p>Engineering, innovating and rapid prototyping. Generating hardware ready for tomorrow's collection opportunities.</p>
                <QuestionTitle>What do you like about the work?</QuestionTitle>
                <p>
                    That we are given a problem to solve, given the funding and resources to solve the challenge in alternative ways. Having the resources,
                    commitment, people and time required for a project is every engineer's dream, and we are in a good spot to spark innovation.
                </p>
                <QuestionTitle>How would you describe the DSC work culture?</QuestionTitle>
                <p>
                    A soul of innovation, mission oriented and target focused. No one is here for inflated salaries but are genuinely here to solve complex
                    problems.
                </p>
                <QuestionTitle>What benefits do you enjoy?</QuestionTitle>
                <ul>
                    <li>Resources - Availability of resources to get the job done.</li>
                    <li>Stability - This isn't contract driven work. The teams are stable and committed, with both short and long term visions.</li>
                    <li>
                        Work Perks - Truly flexible work arrangements. 6 weeks of leave plus Mandatory Rest Days (MRDs) leave a good amount of time for living.
                    </li>
                </ul>
                <QuestionTitle>What training and development have you received?</QuestionTitle>
                <p>Both formal courses and informal training. If you don't know something that's required, you'll be trained.</p>
                <QuestionTitle>What are some highlights?</QuestionTitle>
                <p>
                    Seeing our devices help gather evidence. It's exciting and rewarding knowing that real crime is being actively investigated via a device
                    we've made.
                </p>
                <QuestionTitle>Anything else you want to share?</QuestionTitle>
                <p>
                    The main reason why I joined - "I'm a Q looking for my Bond". DSC really has the right mix of all the elements to successfully innovate and
                    catch bad guys. The correct concoction of people and funding; the culture to explore and take risks, understanding that sometime we try and
                    fail; the ability to trust that everyone is working towards the same goal.
                </p>

                <PageBlockNextPageDivider>
                    <CTALink $invert $borderColour={primaryColour} to='/careers/opportunities' rel='next'>
                        <span>Opportunities</span>
                        <ArrowRight />
                    </CTALink>
                </PageBlockNextPageDivider>
            </PageBlock>
        </PageLayout>
    );
};

export default OurPeoplePage;
